/**
 * 模块名称: 代理商订单
 * @author lids@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import FinishedList from './FinishedList'
import AuditList from './AuditList'
import AllAgentList from './AllAgentList'
import AgentDetail from '../OrderDirect/module/DirectDetail'
import Apply from '../OrderDirect/module/Apply'
// import AddApply from '../OrderDirect/module/AddApply'
import Payback from '../OrderDirect/module/Payback'
import Paybacks from '../OrderDirect/module/Paybacks'
import PaybackDetail from '../OrderDirect/module/PaybackDetail'
import Refund from '../OrderDirect/module/Refund'
import RefundEdit from '../OrderDirect/module/RefundEdit'
import RefundDetail from '../OrderDirect/module/RefundDetail'

const Index = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/finishedList'} exact component={FinishedList} />
      <Route path={match.path + '/auditList'} exact component={AuditList} />
      <Route path={match.path + '/allAgentList'} exact component={AllAgentList} />
      <Route path={match.path + '/add'} exact component={Apply} />
      <Route path={match.path + '/edit'} exact component={Apply} />
      <Route path={match.path + '/renew'} exact component={Apply} />
      <Route path={match.path + '/agentDetail'} exact component={AgentDetail} />
      <Route path={match.path + '/paybackApply'} exact component={Payback} />
      <Route path={match.path + '/paybackApplys'} exact component={Paybacks} />
      <Route path={match.path + '/paybackDetail'} exact component={PaybackDetail} />
      <Route path={match.path + '/refundApply'} exact component={Refund} />
      <Route path={match.path + '/refundEdit'} exact component={RefundEdit} />
      <Route path={match.path + '/refundDetail'} exact component={RefundDetail} />
      <Route component={FinishedList} />
    </Switch>
  )
}

export default Index