/**
 * 模块名称: 直销订单-审批中订单
 * @author lids@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import moment from 'moment'
import { Link } from 'react-router-dom'
import {
  Table,
  Form,
  Icon,
  Input,
  Button,
  Select,
  Modal,
  message,
  Alert,
  DatePicker,
  Popconfirm,
  Switch,
  Tooltip
} from 'antd'
import CorpFilter from '@/components/CorpFilter'
import { parseSearch, debounce } from '@/utils'
import { useSelector } from 'react-redux'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let companyId = []
let searchParamsTmp = {
  limit: pageSize, 
  page: currentPage,
  companyId: companyId
}
let storeFilterName = ''
let storeFilterContent = {}
let shouldMe = null
let apiOperateTimer = true

let distList = [{
  id: 0,
  name: '未分配'
}, {
  id: 1,
  name: '已分配'
}]

const AuditList = (props) => {
  const { history, match, location } = props

  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

  const [filterName, setFilterName] = useState(undefined)

  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [filter, setFilter] = useState({})
  
  const [loading, setLoading] = useState(false)

  const [departmentList, setDepartmentList] = useState([])
  const [visible, setVisible] = useState(false)
  const [filterModelvisible, setFilterModelvisible] = useState(false)
  const [filterContent, setFilterContent] = useState([])
  const [showAll, setShowAll] = useState(false)
  const [projectId, setProjectId] = useState('')
  const [productId, setProductId] = useState('')
  const [businessId, setBusinessId] = useState('')
  const [productList, setProductList] = useState([])
  const [businessList, setBusinessList] = useState([])
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)
  const [shouldMeDis, setShouldMeDis] = useState(true)//风控，防止用户频繁点击switch
  const [isdist, setIsdist] = useState(false)//是否显示分配按钮
  const [selectedRowKeysData, setSelectedRowKeysData] = useState([])
  const [distributionVisible, setDistributionVisible] = useState(false)
  const [disStaffName, setDisStaffName] = useState('')
  const [distributionStaff, setDistributionStaff] = useState([])
  const [distributionName, setDistributionName] = useState([])
  const [distributionList, setDistributionList] = useState([])

  const search = parseSearch(props.location.search)

  const companyList = useSelector(state => state.companyList)
  
  useEffect(() => {
    // console.log(history.location.state)
		if (!history.location.state) {
      currentPage = search.page ? +search.page : 1
      pageSize = search.limit ? +search.limit : 10
      searchParamsTmp = {
        limit: pageSize, 
        page: currentPage
      }
    } else {
      currentPage = search.page ? +search.page : currentPage
      pageSize = search.limit ? +search.limit : pageSize
      initGoback()
    }
    
    companyId = []
    storeFilterName = ''
    storeFilterContent = {}
    shouldMe = null
    apiOperateTimer = true
    if(parseSearch(props.location.search).oaid){
      searchParamsTmp.clientId = parseSearch(props.location.search).oaid
      setFieldsValue({
        'clientId': parseSearch(props.location.search).oaid
      })
    }
    getOrderDirectAuditList()
    getDepartmentList()
    getOrderDistributionList()
  }, [])

  const initGoback = () => {
    // console.log(searchParamsTmp)
    if (searchParamsTmp.project) {
      setProjectId(searchParamsTmp.project)
      api.getFortuneOrderProjectGetChildInfo({id: searchParamsTmp.project}).then(res => setProductList(res.list))
    }
    if (searchParamsTmp.product) {
      setProductId(searchParamsTmp.product)
      api.getFortuneOrderProjectGetChildInfo({id: searchParamsTmp.product}).then(res => setBusinessList(res.list))
    }
    let timer = setTimeout(() => {
      initFieldsVal(searchParamsTmp)
      setFieldsValue({
        appliedate: searchParamsTmp.appliedateMin ? [moment(searchParamsTmp.appliedateMin), moment(searchParamsTmp.appliedateMax)] : undefined,
        acceptedcwat: searchParamsTmp.acceptedcwatMin ? [moment(searchParamsTmp.acceptedcwatMin), moment(searchParamsTmp.acceptedcwatMax)] : undefined,
        affectedate: searchParamsTmp.affectedateMin ? [moment(searchParamsTmp.affectedateMin), moment(searchParamsTmp.affectedateMax)] : undefined,
        creditEnd: searchParamsTmp.creditEndMin ? [moment(searchParamsTmp.creditEndMin), moment(searchParamsTmp.creditEndMax)] : undefined
      })
      clearTimeout(timer)
    }, 1000)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  //点击选择所属公司
  const onChangeCorp = (data) => {
    //console.log('onChangeCorp', data)
    setDepartmentList([])
    setFieldsValue({'userDeptId': undefined})
    currentPage = 1
    history.replace(match.path)
    if(data.length) {
      let arr = []
      data.forEach((item, index) => {
        arr.push(item.id)
      })
      companyId = [].concat(arr)
      searchParamsTmp = {
        limit: pageSize, 
        page: currentPage,
        companyId: companyId
      }
    }else{
      companyId = []
      searchParamsTmp = {
        limit: pageSize, 
        page: currentPage
      }
    }
    getOrderDirectAuditList()
    getDepartmentList()
  }
  //点击重置
  const onReset = () => {
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage,
      companyId: companyId
    }
    shouldMe = null
    setSelectedRowKeysData([])
    setDistributionName([])
    resetFields()
    setFilterName(undefined)
		setStatusValName(undefined)
    getOrderDirectAuditList()
    setProjectId('')
    setProductId('')
    setBusinessId('')
    setProductList([])
    setBusinessList([])
  }

  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getOrderDirectAuditList()
  }

  //获取列表
  const getOrderDirectAuditList = () => {
    if(apiOperateTimer){
      setLoading(true)
      if(companyId.length)searchParamsTmp.companyId = companyId
      if(shouldMe){
        searchParamsTmp.shouldMe = shouldMe
      }else{
        for(let i in searchParamsTmp){
          if(i == 'shouldMe')delete searchParamsTmp[i]
        }
      }
      api.getOrderDirectAuditList(searchParamsTmp).then(res => {
        if(res.ids){
          localStorage.setItem('orderDids', JSON.stringify(res.ids))
        }else{
          localStorage.setItem('orderDids', JSON.stringify([]))
        }
        setCount(res.count)
        let list = res.list
        list.forEach((item, index, self) => {
          // IE11以下该方法不支持
          // self[index]['del'] = item.options.findIndex(i => i === 'del') > -1 ? true : false
          // self[index]['edit'] = item.options.findIndex(i => i === 'edit') > -1 ? true : false

          if(item.option){
            self[index]['del'] = JSON.stringify(item.option).indexOf('del') > -1 ? true : false
            self[index]['edit'] = JSON.stringify(item.option).indexOf('edit') > -1 ? true : false
          }
        })
        setList(list)
        setFilter(res.filter)
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
      apiOperateTimer = false
      setShouldMeDis(false)
      let timer = setTimeout(() => {
        apiOperateTimer = true
        setShouldMeDis(true)
        clearTimeout(timer)
      }, 1000)
    }else{
      return message.info('请不要频繁点击')
    }
  }

  //获取分配人列表
  const getOrderDistributionList = () => {
    api.getOrderDistributionList().then(res => {
      setDistributionStaff(res.distributionStaff)
      setIsdist(res.isdist)
    })
  }

  //获取部门列表
  const getDepartmentList = () => {
    api.getDepartment({limit: global.paramsLimit, needAuth: 0, companyId}).then(res => {
      setFieldsValue({'userDeptId': undefined})
      setDepartmentList(res.list)
    })
  }

  //数据转换
  const transData = (vals) => {
    if(vals !== undefined){
      for(let i in vals){
        if(vals[i] !== undefined){
          if(i == 'appliedate'){
            searchParamsTmp.appliedateMin = moment(vals[i][0]).format('YYYY-MM-DD')
            searchParamsTmp.appliedateMax = moment(vals[i][1]).format('YYYY-MM-DD')
          }else if(i == 'acceptedcwat'){
            searchParamsTmp.acceptedcwatMin = moment(vals[i][0]).format('YYYY-MM-DD')
            searchParamsTmp.acceptedcwatMax = moment(vals[i][1]).format('YYYY-MM-DD')
          }else if(i == 'affectedate'){
            searchParamsTmp.affectedateMin = moment(vals[i][0]).format('YYYY-MM-DD')
            searchParamsTmp.affectedateMax = moment(vals[i][1]).format('YYYY-MM-DD')
          }else if(i == 'creditEnd'){
            searchParamsTmp.creditEndMin = moment(vals[i][0]).format('YYYY-MM-DD')
            searchParamsTmp.creditEndMax = moment(vals[i][1]).format('YYYY-MM-DD')
          }else if(i == 'userName'){
            if(typeof parseInt(vals[i]) === 'number' && !isNaN(vals[i])){
              //纯数字
              searchParamsTmp.userNumber = vals[i]
            }else{
              //非纯数字
              searchParamsTmp.userName = vals[i]
            }
          }else{
            searchParamsTmp[i] = vals[i]
          }
        }
      }
    }
  }
  //点击筛选
  const searchSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //console.log(vals)
      transData(vals)
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getOrderDirectAuditList()
        setFilterName(undefined)
      }
    })
    
  }
  const onSetShouldMe = e => {
    if(e == true){
      shouldMe = 1
    }else{
      shouldMe = null
    }
    getOrderDirectAuditList()
  }
  //选择保存方案
  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    val && api.getSearchStorage({key: location.pathname}).then(res => {
      setFilterContent(res.list)
    })
  }
  //选择方案
  const onFilterChecked = (item, val2) => {
    // console.log('item=>', item)
    setFilterName(item)
    setStatusValName(val2.props.children.props.children[0])
    // let data = JSON.parse(item)
    let data = filterContent[item].content
    searchParamsTmp = {...searchParamsTmp, ...data}
    if(data.project){
      setProjectId(data.project)
      api.getFortuneOrderProjectGetChildInfo({id: data.project}).then(res => {
        setProductList(res)
      })
    }
    if(data.product){
      setProductId(data.product)
      api.getFortuneOrderProjectGetChildInfo({id: data.product}).then(res => {
        setBusinessList(res)
      })
    }
    if(data.business)setBusinessId(data.business)
    initFieldsVal(data)
    setFieldsValue({
      appliedate: data.appliedate ? [moment(data.appliedate[0]), moment(data.appliedate[1])] : undefined,
      acceptedcwat: data.acceptedcwat ? [moment(data.acceptedcwat[0]), moment(data.acceptedcwat[1])] : undefined,
      affectedate: data.affectedate ? [moment(data.affectedate[0]), moment(data.affectedate[1])] : undefined,
      creditEnd: data.creditEnd ? [moment(data.creditEnd[0]), moment(data.creditEnd[1])] : undefined
    })
  }
  // 填充表单值
  const initFieldsVal = data => {
    setFieldsValue({
      number: data.number ? data.number : undefined,
      vendor: data.vendor ? data.vendor : undefined,
      userName: data.userName ? data.userName : undefined,
      currentUserName: data.currentUserName ? data.currentUserName : undefined,
      userDeptId: data.userDeptId ? data.userDeptId : undefined,
      client: data.client ? data.client : undefined,
      clientId: data.clientId ? data.clientId : undefined,
      oldClientId: data.oldClientId ? data.oldClientId : undefined,
      type: data.type ? data.type : undefined,
      statusType: data.statusType ? data.statusType : undefined,
      project: data.project ? data.project : undefined,
      product: data.product ? data.product : undefined,
      business: data.business ? data.business : undefined,
      figtype: data.figtype ? data.figtype : undefined,
      auditStatus: data.auditStatus ? data.auditStatus : undefined,
      coltype: data.coltype ? data.coltype : undefined,
      colstatus: data.colstatus ? data.colstatus : undefined,
      fundCompanyId: data.fundCompanyId ? data.fundCompanyId : undefined,
      contractno: data.contractno ? data.contractno : undefined,
      dist: data.dist ? data.dist : undefined,
      // distribution: data.distribution ? data.distribution : undefined,
    })
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  //保存筛选条件
  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      //console.log(vals)
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item === '' || item !== undefined)) {
          storeFilterContent = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'inpicker' || k === 'onpicker') {
                storeFilterContent[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else {
                storeFilterContent[k] = vals[k]
              }
            }
          }
          setFilterModelvisible(true)
        } else {
          message.error('请选择筛选内容')
        }
      }
    })
  }
  //保存筛选点击确定
  const filterHandleOk = () => {
    validateFields((err, vals) => {
      api.saveSearchStorage({
        name: storeFilterName,
        key: location.pathname,
        content: storeFilterContent
      }).then(() =>{
        setFilterModelvisible(false)
        message.success('添加成功')
      })
    })
  }
  //取消筛选点击确定
  const filterHandleCancel = () => {
    setFilterModelvisible(false)
  }
  //修改筛选名称
  const onFilterInfoChange = (e) => {
    storeFilterName = e.target.value
  }

  //获得项目id
  const onChangeProject = (val) => {
    setProjectId(val)
    setProductId('')
    setBusinessId('')
    setFieldsValue({
      'product': undefined,
      'business': undefined
    })
    api.getFortuneOrderProjectGetChildInfo({id: val}).then(res => {
      setProductList(res.list)
    })
  }

  //获得产品id
  const onChangeProduct = (val) => {
    setProductId(val)
    setBusinessId('')
    setFieldsValue({
      'business': undefined
    })
    api.getFortuneOrderProjectGetChildInfo({id: val}).then(res => {
      setBusinessList(res.list)
    })
  }

  //获得业务类型id
  const onChangeBusiness = (val) => {
    setBusinessId(val)
  }

  //删除订单
  const onFortuneOrderDel = id => {
    api.onFortuneOrderDel({id}).then(res => {
      message.success('订单删除成功')
      getOrderDirectAuditList()
    })
  }
  //分页
  const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
    },
    onSelect: (record, selected, selectedRows) => {
      // console.log(record, selected, selectedRows)
      if(selected){
        setDistributionName([...new Set([...distributionName, record])])
        setSelectedRowKeysData([...new Set([...selectedRowKeysData, record.id])])
      }else{
        setDistributionName(distributionName.filter(item => item.id !== record.id))
        setSelectedRowKeysData(selectedRowKeysData.filter(item => item !== record.id))
      }
    },
    //全选
		onSelectAll: (selected, selectedRows, changeRows) => {
      // console.log(selected, selectedRows, changeRows)
      let arr = []
      let arrNew = []
			changeRows.forEach(item => {
				arr.push(item.id)
			})
      changeRows.forEach(item => {
				arrNew.push(item)
			})
			if(selected){
        setDistributionName([...new Set([...distributionName, ...arrNew])])
        setSelectedRowKeysData([...new Set([...selectedRowKeysData, ...arr])])
			}else{
        setDistributionName(distributionName.filter(item => !arrNew.includes(item)))
				setSelectedRowKeysData(selectedRowKeysData.filter(item => !arr.includes(item)))
			}
    },
    selectedRowKeys: selectedRowKeysData,
    getCheckboxProps: record => ({
      disabled: record.candist != 1,
      candist: record.candist
    })
  }
  //全选
  const onAllelection = () => {
    let IDS = JSON.parse(localStorage.getItem('orderDids'))
    let arr = []
    for (let i = 0; i < IDS.length; i++) {
      arr[i] = IDS[i]
    }
    setSelectedRowKeysData(arr)
  }
  //反选
  const onReverse = () => {
    let IDS = JSON.parse(localStorage.getItem('orderDids'))
    let arr = []
    for (let i = 0; i < IDS.length; i++) {
      arr[i] = IDS[i].id
    }
    let filterIds = []
    arr.forEach(item => {
      if (!selectedRowKeysData.includes(item)) {
        filterIds.push(item)
      }
    })
    setSelectedRowKeysData(filterIds)
  }

  //分配
	const oprateHandleOkDist = () => {
    // console.log(disStaffName, distributionName, selectedRowKeysData, distributionName.every((item, index) => item.distributionName))
    if(disStaffName === '')return message.warning('请选择审批人')
    if (!distributionName.every(item => item.distributionName == null)) {
      let data = distributionName.filter(item => {
        return item.distributionName !== null
      })
      // console.log(data)
      setDistributionList(data)
      return setVisible(true)
    }
		onFortuneOrderFlowDistribution()
	}

  const onFortuneOrderFlowDistribution = () => {
    api.onFortuneOrderFlowDistribution({id: selectedRowKeysData.join(','), stfid: disStaffName}).then(() => {
      message.info('分配成功')
      setDistributionVisible(false)
      setVisible(false)
      setDistributionName([])
      setSelectedRowKeysData([])
      getOrderDirectAuditList()
      setDisStaffName('')
    })
  }

  //再次分配确认框
  const handleOk = () => {
    onFortuneOrderFlowDistribution()
  }

  return (
    <>
      <Modal
        title="确认框"
        visible={visible}
        onOk={debounce(() => {handleOk()}, 1500)}
        onCancel={() => setVisible(false)}
      >
        <>
          {
            distributionList.map((v, i) => {
              return <div key={v.id}>订单{v.number}已分配给{v.distributionName}</div>
            })
          }
          <div>请点击确认，以对该订单进行重新分配</div>
        </>
      </Modal>
      <CorpFilter onChange={onChangeCorp} />
      <div className="search-askforleave-wrap">
        <h4 className="title">其它选项：</h4>
        {
          !showAll ? <span className="askforleave-filter-control" onClick={() => setShowAll(true)}>展开<Icon type="down" /></span>
          : <span className="askforleave-filter-control" onClick={() => setShowAll(false)}>收起<Icon type="up" /></span>
        }
        <Form onSubmit={searchSubmit} {...formItemLayout}>
          <div className={`form-box${showAll ? ' askforleave-form-auto' : ''}`}>
            <FormItem label="订单编号">
              {getFieldDecorator('number')(<Input 
                placeholder="请输入订单编号" 
              />)}
            </FormItem>
            <FormItem label="厂商ID">
              {getFieldDecorator('vendor')(<Input 
                placeholder="请输入厂商ID" 
              />)}
            </FormItem>
            <FormItem label="提单人">
              {getFieldDecorator('userName')(<Input 
                placeholder="请输入提单人" 
              />)}
            </FormItem>
            <FormItem label="负责人">
              {getFieldDecorator('currentUserName')(<Input 
                placeholder="请输入负责人" 
              />)}
            </FormItem>
            <FormItem label="部门">
              {getFieldDecorator('userDeptId')(<Select
                placeholder="全部"
                showSearch={true}
                optionFilterProp="children"
              >
                {
                  departmentList.map(v => {
                    return !companyId.length ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="客户名称">
              {getFieldDecorator('client')(<Input 
                placeholder="请输入客户名称" 
              />)}
            </FormItem>
            <FormItem label="XOAID">
              {getFieldDecorator('clientId')(<Input 
                placeholder="请输入XOAID" 
              />)}
            </FormItem>
            <FormItem label="老ID">
              {getFieldDecorator('oldClientId')(<Input 
                placeholder="请输入老ID" 
              />)}
            </FormItem>
            <FormItem label="类型">
              {getFieldDecorator('type')(<Select
                placeholder="全部"
              >
                {
                  [{id: 0, name: '直销'}, {id: 4, name: '渠道直销'}].map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="订单类型">
              {getFieldDecorator('statusType')(<Select
                placeholder="全部"
              >
                {
                  [{id: 0, name: '提单'}, {id: 1, name: '退单'}].map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="项目">
              {getFieldDecorator('project')(<Select
                placeholder="全部"
                onChange={onChangeProject}
              >
                {
                  filter.project && filter.project.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="产品" style={{display: projectId ? 'block' : 'none'}}>
              {getFieldDecorator('product')(<Select
                placeholder="全部"
                onChange={onChangeProduct}
              >
                {
                  productList.length > 0 && productList.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="业务类型" style={{display: productId ? 'block' : 'none'}}>
              {getFieldDecorator('business')(<Select
                placeholder="全部"
                onChange={onChangeBusiness}
              >
                {
                  businessList.length > 0 && businessList.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="业绩类型">
              {getFieldDecorator('figtype')(<Select
                placeholder="全部"
              >
                {
                  filter.figure && filter.figure.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="订单状态">
              {getFieldDecorator('auditStatus')(<Select
                placeholder="全部"
                mode="multiple"
                showArrow
              >
                {
                  filter.auditStatus && filter.auditStatus.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="收款类型">
              {getFieldDecorator('coltype')(<Select
                placeholder="全部"
              >
                {
                  filter.coltype && filter.coltype.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="收款状态">
              {getFieldDecorator('colstatus')(<Select
                placeholder="全部"
              >
                {
                  [{name: '已收款', id: 1}, {name: '未收款', id: 2}].map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="资金所属公司">
              {getFieldDecorator('fundCompanyId')(<Select
                placeholder="全部"
              >
                {
                  companyList.map(item => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="分配状态">
              {getFieldDecorator('dist')(<Select
                placeholder="全部"
              >
                {
                  distList.map(item => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="合同编号">
              {getFieldDecorator('contractno')(<Input 
                placeholder="请输入合同编号" 
              />)}
            </FormItem>
            {/* <FormItem label="已分配订单">
              {getFieldDecorator('distribution')(<Select
                placeholder="全部"
              >
                {
                  filter.distribution && filter.distribution.map((item, index) => {
                    return <Option key={item.id}>{item.staffName}</Option>
                  })
                }
              </Select>)}
            </FormItem> */}
            <FormItem label="申请日期">
              {getFieldDecorator('appliedate', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
            <FormItem label="财务通过日期">
              {getFieldDecorator('acceptedcwat', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
            <FormItem label="生效日期">
              {getFieldDecorator('affectedate', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
            <FormItem label="负利到期日期">
              {getFieldDecorator('creditEnd', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
          </div>
          <FormItem className="btns" label=" " colon={false} labelCol={{xs: { span: 4 }, sm: { span: 4 }}} wrapperCol={{xs: { span: 20 }, sm: { span: 20 }}}>
            <div className="staff-filter-btns">
              {showAll && <>
                <h4 className="filter-title">已保存筛选方案：</h4>
                <div className="filter-select">
                  <Select style={{display: 'inline-block', width: '160px'}} placeholder="请选择" onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)} onSelect={onFilterChecked} value={filterName}> 
                    {filterContent.map((item, index) =>
                      <Option value={index} key={index}>
                          <div className="closeStyBtn">
                          {item.name}
                          {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                              e.stopPropagation()
                              onSearchDelete(item.id)
                          }} /></span> : null}
                          </div>
                      </Option>
                    )}
                  </Select>
                </div>
                <Button className="filter-savebtn" onClick={onSaveFilterTerm}>保存筛选条件</Button>
              </>}
              <Switch 
            checkedChildren="待审批" 
            unCheckedChildren="待审批" 
            disabled={!shouldMeDis} 
            onClick={onSetShouldMe} 
            className="shouldme-switch-btn" 
            checked={shouldMe ? true : false} 
          />
              <Button onClick={onReset}>重置</Button>
              <Button type="primary" htmlType="submit">筛选</Button>
              <Modal
                title="保存筛选条件"
                visible={filterModelvisible}
                onOk={filterHandleOk}
                onCancel={filterHandleCancel}
              >
                <Input placeholder="请输入筛选名称" onChange={onFilterInfoChange}/>
              </Modal>
            </div>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <div className="add-wrap">
        {isdist == true && <Tooltip title="批量分配审批人">
          <Button type="primary" icon="" style={{marginLeft: 10}} onClick={() => setDistributionVisible(true)}>分配</Button>
        </Tooltip>}
      </div>
      <Alert className="corp-count" message={
        <>
          总计：{count}条数据
          {/* <span>，已选择 <span style={{ color: '#168FFF' }}>{selectedRowKeysData.length}</span> 项</span>
          {isdist && selectedRowKeysData.length !== 0 && <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onReverse}>反选</span>}
          {isdist && selectedRowKeysData.length == 0 //eslint-disable-line
            && <span style={{ marginLeft: 10, color: '#ccc', cursor: 'default' }}>反选</span>}
          {isdist && <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onAllelection}>全选</span>} */}
        </>
      } type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        className="askforleave-table"
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
        scroll={{x: 2500}}
        rowSelection={isdist ? rowSelection : null}
      >
        <Column title="序号" dataIndex="headIndex" fixed="left" width={80} />
        {/* <Column title="订单ID" dataIndex="id" /> */}
        <Column title="订单编号" dataIndex="number" fixed="left" width={160} render={(text, record) => {
          if(record.batchAuditNo){
            if(record.batchAuditNo.indexOf('payback@') !== -1){
              return <Link to={`/orderDirect/paybackDetail?id=${record.id}&type=zhi&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text}</Link>
            }else{
              return <span className="hrefspan" onClick={() => {
                history.push(`/orderDirect/refundDetail`, { 
                  ids: record.batchIds,
                  vendor: record.vendor,
                  type: 'unfinishedzhi'
                })
              }}>{text}</span>
            } 
          }else{
            return <Link to={`/orderDirect/directDetail?id=${record.id}&type=unfinishedzhi&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text}</Link>
          }
        }}/> 
        <Column title="资金所属公司" key="fundCompanyName" width={160} render={(text, record) => {
          return (record.fundCompanyId == 1 || record.fundCompanyId == 2 || record.fundCompanyId == 4) ? <>{record.fundCompanyName}</> : 
          <span style={{color: '#1890ff'}}>{record.fundCompanyName}</span>
        }} /> 
        <Column title="客户名称" dataIndex="client" width={180} />
        <Column title="XOAID" dataIndex="clientId" />
        <Column title="老ID" dataIndex="oldClientId" />
        <Column title="类型" key="type" render={(text, record) => {
          if(record.type == 0) return '直销'
          if(record.type == 1) return '代理商'
          if(record.type == 3) return '直销渠道'
          if(record.type == 4) return '渠道直销'
        }} />
        <Column title="订单类型" key="orderType" render={(text, record) => record.statusType == 0 ? <span style={{color: '#87d068'}}>提单</span> : <span style={{color: '#f50'}}>退单</span>} />
        <Column title="订单状态" dataIndex="auditStatusName" />
        <Column title="分配至" dataIndex="distributionName" />
        <Column title="项目" dataIndex="projectName" />
        <Column title="产品" dataIndex="productName" /> 
        <Column title="厂商ID" dataIndex="vendor" />
        <Column title="业务类型" dataIndex="businessName" />
        <Column title="市场价" key="colmarketprice" render={(text, record) => record.statusType == 0 ? record.colmarketprice : (-record.colmarketprice).toFixed(2)} />
        <Column title="实收" key="colactualprice" render={(text, record) => record.statusType == 0 ? record.colactualprice : (-record.colactualprice).toFixed(2)} />
        <Column title="预计收款" key="colpredictprice" render={(text, record) => record.statusType == 0 ? record.colpredictprice : (-record.colpredictprice).toFixed(2)} />
        <Column title="个人利润" key="colprofit" render={(text, record) => record.statusType == 0 ? record.colprofit : (-record.colprofit).toFixed(2)} />
        {/* <Column title="市场价" key="colmarketprice" render={(text, record) => record.statusType == 0 ? record.colmarketprice : record.colstatus !== '未收款' ? (-record.colmarketprice).toFixed(2) : '--'} />
        <Column title="实收" key="colactualprice" render={(text, record) => record.statusType == 0 ? record.colactualprice : record.colstatus !== '未收款' ? (-record.colactualprice).toFixed(2) : '--'} />
        <Column title="预计收款" key="colpredictprice" render={(text, record) => record.statusType == 0 ? record.colpredictprice : record.colstatus !== '未收款' ? (-record.colpredictprice).toFixed(2) : '--'} />
        <Column title="个人利润" key="colprofit" render={(text, record) => record.statusType == 0 ? record.colprofit : record.colstatus !== '未收款' ? (-record.colprofit).toFixed(2) : '--'} /> */}
        <Column title="退单信息" dataIndex="refundInfo" />
        <Column title="提单人" dataIndex="userName" />
        <Column title="收款类型" dataIndex="coltype" />
        <Column title="收款状态" dataIndex="colstatus" />
        <Column title="订单负责人" dataIndex="currentUserName" />
        <Column title="负利到期日期" dataIndex="creditEnd" />
        <Column title="操作" key="oprate" fixed="right" width={170} render={(text, record) => {
          return <>
            {
              record.batchAuditNo ? 
                record.batchAuditNo.indexOf('payback@') !== -1 ? 
                <Tooltip title="在新页面打开详情页" placement="bottom">
                  <Link target='_blank' to={`/orderDirect/paybackDetail?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>新页面</Link>
                </Tooltip> :
                <Tooltip title="在新页面打开详情页" placement="bottom">
                  <Link target='_blank' to={`/orderDirect/refundDetail?ids=${record.batchIds}&vendor=${record.vendor}&time=${+new Date()}&sign=${CryptoJS.MD5(record.batchIds+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>新页面</Link>
                </Tooltip> : 
              <Tooltip title="在新页面打开详情页" placement="bottom">
                <Link target='_blank' to={`/orderDirect/directDetail?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>新页面</Link>
              </Tooltip>
            }
            {' '}
            {record.del === true && <Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={() => onFortuneOrderDel(record.id)}>
              <Button type="danger" size="small">删除</Button>
            </Popconfirm>}
            {' '}
            {record.edit === true && (record.statusType !== 1 ? <Button type="primary" size="small" onClick={() => {
              history.push('/orderDirect/edit', {
                relationId: record.clientId, 
                id: record.id, 
                auditStatus: record.auditStatus,
                vendorEdit: record.vendor ? record.vendor : ''
              })
            }}>编辑</Button> : (record.coltype !== '信用收款' ? <Button type="primary" size="small" onClick={() => {
              history.push('/orderDirect/refundEdit', {id: record.batchIds.split(',')[0]})
            }}>编辑</Button> : null))}
          </>
        }} />
      </Table>
      <Modal
        title="分配"
        visible={distributionVisible}
        onOk={debounce(() => {oprateHandleOkDist()}, 1500)}
        onCancel={() => {
          setDisStaffName('')
          setDistributionVisible(false)
        }}
        destroyOnClose={true}
      >
        <div className="officework-modal-content">
          <span style={{display: 'inline-block', width: '15%', height: '30px', lineHeight: '30px'}}><span style={{color: 'red'}}>*</span> 审批人</span>
          <Select
            placeholder="请选择审批人"
            style={{width: '80%'}}
            onChange={val => setDisStaffName(val)}
          >
            {
              distributionStaff && distributionStaff.map(item => {
                return <Select.Option key={item.id}>{item.staffName}</Select.Option>
              })
            }
          </Select>
        </div>
      </Modal>
    </>
  )
}

export default Form.create()(AuditList)