/**
 * 模块名称: 代理商订单-所有订单
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import Export from '@/components/Export'
import { Link } from 'react-router-dom'
import {
  Table,
  Form,
  Icon,
  Input,
  Button,
  Select,
  Modal,
  message,
  Alert,
  DatePicker,
  Spin,
  Drawer
} from 'antd'
import CorpFilter from '@/components/CorpFilter'
import RefundList from '../OrderDirect/module/RefundList'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let companyId = []
let searchParamsTmp = {
  limit: pageSize,
  page: currentPage,
  companyId: companyId
}
let storeFilterName = ''
let storeFilterContent = {}

let allFilter = {}

let selectedIds = ''

const AllAgentList = (props) => {
  const { history, match, location } = props

  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

  const productsRef = useRef()

  const [filterName, setFilterName] = useState(undefined)

  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [filter, setFilter] = useState({})

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  const [drawerLoading, setDrawerLoading] = useState(false)

  const [departmentList, setDepartmentList] = useState([])
  const [filterModelvisible, setFilterModelvisible] = useState(false)
  const [filterContent, setFilterContent] = useState([])
  const [showAll, setShowAll] = useState(false)
  const [projectId, setProjectId] = useState('')
  const [productId, setProductId] = useState('')
  // const [businessId, setBusinessId] = useState('')
  const [productList, setProductList] = useState([])
  const [businessList, setBusinessList] = useState([])
  const [authList, setAuthList] = useState([])//按钮权限
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)
  const [showExport, setShowExport] = useState(false)

  const [checked, setChecked] = useState(0)
  const [checkedIdArr, setCheckedIdArr] = useState([])
  const [ids, setIds] = useState([])

  const search = parseSearch(props.location.search)

  useEffect(() => {
    // console.log(history.location.state)
    if (!history.location.state) {
      currentPage = search.page ? +search.page : 1
      pageSize = search.limit ? +search.limit : 10
      searchParamsTmp = {
        limit: pageSize,
        page: currentPage
      }
    } else {
      currentPage = search.page ? +search.page : currentPage
      pageSize = search.limit ? +search.limit : pageSize
      initGoback()
    }
    
    companyId = []
    allFilter = {}
    selectedIds = ''
    storeFilterName = ''
    storeFilterContent = {}
    if (parseSearch(props.location.search).oaid) {
      searchParamsTmp.clientId = parseSearch(props.location.search).oaid
      setFieldsValue({
        'clientId': parseSearch(props.location.search).oaid
      })
    }
    getFortuneOrderChannelAllList()
    getPageAuth()
    getDepartmentList()
  }, [])

  const initGoback = () => {
    // console.log(searchParamsTmp)
    if (searchParamsTmp.project) {
      setProjectId(searchParamsTmp.project)
      api.getFortuneOrderProjectGetChildInfo({id: searchParamsTmp.project}).then(res => setProductList(res.list))
    }
    if (searchParamsTmp.product) {
      setProductId(searchParamsTmp.product)
      api.getFortuneOrderProjectGetChildInfo({id: searchParamsTmp.product}).then(res => setBusinessList(res.list))
    }
    let timer = setTimeout(() => {
      initFieldsVal(searchParamsTmp)
      setFieldsValue({
        appliedate: searchParamsTmp.appliedateMin ? [moment(searchParamsTmp.appliedateMin), moment(searchParamsTmp.appliedateMax)] : undefined,
        acceptedcwat: searchParamsTmp.acceptedcwatMin ? [moment(searchParamsTmp.acceptedcwatMin), moment(searchParamsTmp.acceptedcwatMax)] : undefined,
        affectedate: searchParamsTmp.affectedateMin ? [moment(searchParamsTmp.affectedateMin), moment(searchParamsTmp.affectedateMax)] : undefined,
        creditEnd: searchParamsTmp.creditEndMin ? [moment(searchParamsTmp.creditEndMin), moment(searchParamsTmp.creditEndMax)] : undefined
      })
      clearTimeout(timer)
    }, 1000)
  }

  //按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then(list => {
      setAuthList(list)
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  //点击选择所属公司
  const onChangeCorp = (data) => {
    //console.log('onChangeCorp', data)
    setDepartmentList([])
    setFieldsValue({ 'userDeptId': undefined })
    currentPage = 1
    history.replace(match.path)
    if (data.length) {
      let arr = []
      data.forEach((item, index) => {
        arr.push(item.id)
      })
      companyId = [].concat(arr)
      searchParamsTmp = {
        limit: pageSize,
        page: currentPage,
        companyId: companyId
      }
    } else {
      companyId = []
      searchParamsTmp = {
        limit: pageSize,
        page: currentPage
      }
    }
    getFortuneOrderChannelAllList()
    getDepartmentList()
  }
  //点击重置
  const onReset = () => {
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
      companyId: companyId
    }
    allFilter = {}
    selectedIds = ''
    resetFields()
    setFilterName(undefined)
    setStatusValName(undefined)
    getFortuneOrderChannelAllList()
    setProjectId('')
    setProductId('')
    // setBusinessId('')
    setProductList([])
    setBusinessList([])
    setChecked(0)
    setCheckedIdArr([])
  }

  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getFortuneOrderChannelAllList()
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(selectedRowKeys, selectedRows)
      // setCheckedId(selectedRowKeys.join(","))
      setChecked(selectedRowKeys.length)
      //setCheckedId(checkedIdArr.join(","))
      selectedIds = selectedRowKeys
    },
    onSelect: (record, selected, selectedRows) => {
      // console.log(record, selected, selectedRows)
      //let arr = []
      if (!selected) {
        for (let i = 0; i < checkedIdArr.length; i++) {
          if (checkedIdArr[i] == record.id) {//eslint-disable-line
            checkedIdArr.splice(i, 1)
            i--
          }
        }
      } else {
        checkedIdArr.push(record.id)
      }
      let arrNew = [...new Set(checkedIdArr)]
      setCheckedIdArr(arrNew)
      setChecked(arrNew.length)
      selectedIds = arrNew
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
    //全选一列（默认十条）
    onSelectAll: (selected, selectedRows, changeRows) => {
      //console.log(selected, selectedRows, changeRows)
      let arr = []
      changeRows.forEach(item => {
        arr.push(item.id)
      })
      if (selected) {
        setCheckedIdArr([...new Set([...checkedIdArr, ...arr])])
      } else {
        setCheckedIdArr(checkedIdArr.filter(item => !arr.includes(item)))
      }
      // setChecked(arr.length)
      // selectedIds = arr
    },
    selectedRowKeys: checkedIdArr
  }

  //反选
  const onReverse = () => {
    setLoading(true)
    setTimeout(() => {
      let IDS = JSON.parse(localStorage.getItem('ids'))
      let arr = []
      for (let i = 0; i < IDS.length; i++) {
        arr[i] = IDS[i]
      }
      let filterIds = []
      arr.forEach(item => {
        if (!checkedIdArr.includes(item)) {
          filterIds.push(item)
        }
      })
      setCheckedIdArr(filterIds)
      setChecked(filterIds.length)
      selectedIds = filterIds
      setLoading(false)
    }, 500)
  }

  //全选
  const onAllelection = () => {
    let IDS = JSON.parse(localStorage.getItem('ids'))
    let arr = []
    for (let i = 0; i < IDS.length; i++) {
      arr[i] = IDS[i]
    }
    setChecked(arr.length)
    setCheckedIdArr(arr)
    //console.log(arr)
    selectedIds = arr
  }

  //获取列表
  const getFortuneOrderChannelAllList = () => {
    setLoading(true)
    if (companyId.length) searchParamsTmp.companyId = companyId
    api.getFortuneOrderChannelAllList(searchParamsTmp).then(res => {
      setList(res.list)
      setCount(res.count)
      res.filter && setFilter(res.filter)
      if (currentPage === 1) {
        if (res.ids) {
          localStorage.setItem('ids', JSON.stringify(res.ids))
        } else {
          localStorage.setItem('ids', JSON.stringify([]))
        }
      }
      setIds(res.ids)
      // setChecked(0)
      // setCheckedIdArr([])
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }
  //关闭角色授权弹窗
  const closeDrawer = () => {
    setVisible(false)
  }

  //获取部门列表
  const getDepartmentList = () => {
    api.getDepartment({ limit: global.paramsLimit, needAuth: 0, companyId }).then(res => {
      setFieldsValue({ 'userDeptId': undefined })
      setDepartmentList(res.list)
    })
  }

  //数据转换
  const transData = (vals) => {
    if (vals !== undefined) {
      for (let i in vals) {
        if (vals[i] !== undefined) {
          if (i == 'appliedate') {
            searchParamsTmp.appliedateMin = moment(vals[i][0]).format('YYYY-MM-DD')
            searchParamsTmp.appliedateMax = moment(vals[i][1]).format('YYYY-MM-DD')
          } else if (i == 'acceptedcwat') {
            searchParamsTmp.acceptedcwatMin = moment(vals[i][0]).format('YYYY-MM-DD')
            searchParamsTmp.acceptedcwatMax = moment(vals[i][1]).format('YYYY-MM-DD')
          } else if (i == 'affectedate') {
            searchParamsTmp.affectedateMin = moment(vals[i][0]).format('YYYY-MM-DD')
            searchParamsTmp.affectedateMax = moment(vals[i][1]).format('YYYY-MM-DD')
          } else if (i == 'creditEnd') {
            searchParamsTmp.creditEndMin = moment(vals[i][0]).format('YYYY-MM-DD')
            searchParamsTmp.creditEndMax = moment(vals[i][1]).format('YYYY-MM-DD')
          } else if (i == 'userName') {
            if (typeof parseInt(vals[i]) === 'number' && !isNaN(vals[i])) {
              //纯数字
              searchParamsTmp.userNumber = vals[i]
            } else {
              //非纯数字
              searchParamsTmp.userName = vals[i]
            }
          } else {
            searchParamsTmp[i] = vals[i]
          }
        }
      }
      // console.log('searchParamsTmp=>', searchParamsTmp)
    }
  }

  //申请
  const onRenfundOpen = () => {
    setVisible(true)
  }
  //点击筛选
  const searchSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //console.log(vals)
      transData(vals)
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getFortuneOrderChannelAllList()
        setFilterName(undefined)
        setChecked(0)
        setCheckedIdArr([])
      }
    })

  }
  //选择保存方案
  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(res => {
      setFilterContent(res.list)
    })
  }
  //选择方案
  const onFilterChecked = (item, val2) => {
    // console.log('item=>', item)
    setFilterName(item)
    setStatusValName(val2.props.children.props.children[0])
    // let data = JSON.parse(item)
    let data = filterContent[item].content
    searchParamsTmp = { ...searchParamsTmp, ...data }
    if (data.project) {
      setProjectId(data.project)
      api.getFortuneOrderProjectGetChildInfo({ id: data.project }).then(res => {
        setProductList(res)
      })
    }
    if (data.product) {
      setProductId(data.product)
      api.getFortuneOrderProjectGetChildInfo({ id: data.product }).then(res => {
        setBusinessList(res)
      })
    }
    // if(data.business)setBusinessId(data.business)
    initFieldsVal(data)
    setFieldsValue({
      appliedate: data.appliedate ? [moment(data.appliedate[0]), moment(data.appliedate[1])] : undefined,
      acceptedcwat: data.acceptedcwat ? [moment(data.acceptedcwat[0]), moment(data.acceptedcwat[1])] : undefined,
      affectedate: data.affectedate ? [moment(data.affectedate[0]), moment(data.affectedate[1])] : undefined,
      creditEnd: data.creditEnd ? [moment(data.creditEnd[0]), moment(data.creditEnd[1])] : undefined
    })
  }
  // 填充表单值
  const initFieldsVal = data => {
    setFieldsValue({
      number: data.number ? data.number : undefined,
      vendor: data.vendor ? data.vendor : undefined,
      userName: data.userName ? data.userName : undefined,
      currentName: data.currentName ? data.currentName : undefined,
      userDeptId: data.userDeptId ? data.userDeptId : undefined,
      client: data.client ? data.client : undefined,
      clientId: data.clientId ? data.clientId : undefined,
      agent: data.agent ? data.agent : undefined,
      agentId: data.agentId ? data.agentId : undefined,
      oldAgentId: data.oldAgentId ? data.oldAgentId : undefined,
      type: data.type ? data.type : undefined,
      statusType: data.statusType ? data.statusType : undefined,
      project: data.project ? data.project : undefined,
      product: data.product ? data.product : undefined,
      business: data.business ? data.business : undefined,
      figtype: data.figtype ? data.figtype : undefined,
      auditStatus: data.auditStatus ? data.auditStatus : undefined,
      coltype: data.coltype ? data.coltype : undefined,
      colstatus: data.colstatus ? data.colstatus : undefined,
      contractno: data.contractno ? data.contractno : undefined,
      // distribution: data.distribution ? data.distribution : undefined,
    })
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  //保存筛选条件
  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      //console.log(vals)
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item === '' || item !== undefined)) {
          storeFilterContent = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'inpicker' || k === 'onpicker') {
                storeFilterContent[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else {
                storeFilterContent[k] = vals[k]
              }
            }
          }
          setFilterModelvisible(true)
        } else {
          message.error('请选择筛选内容')
        }
      }
    })
  }
  //保存筛选点击确定
  const filterHandleOk = () => {
    validateFields((err, vals) => {
      api.saveSearchStorage({
        name: storeFilterName,
        key: location.pathname,
        content: storeFilterContent
      }).then(() => {
        setFilterModelvisible(false)
        message.success('添加成功')
      })
    })
  }
  //取消筛选点击确定
  const filterHandleCancel = () => {
    setFilterModelvisible(false)
  }
  //修改筛选名称
  const onFilterInfoChange = (e) => {
    storeFilterName = e.target.value
  }

  //获得项目id
  const onChangeProject = (val) => {
    setProjectId(val)
    setProductId('')
    // setBusinessId('')
    setFieldsValue({
      'product': undefined,
      'business': undefined
    })
    api.getFortuneOrderProjectGetChildInfo({ id: val }).then(res => {
      setProductList(res.list)
    })
  }

  //获得产品id
  const onChangeProduct = (val) => {
    setProductId(val)
    // setBusinessId('')
    setFieldsValue({
      'business': undefined
    })
    api.getFortuneOrderProjectGetChildInfo({ id: val }).then(res => {
      setBusinessList(res.list)
    })
  }

  //获得业务类型id
  const onChangeBusiness = (val) => {
    // setBusinessId(val)
  }

  //导出
  const onExportFile = () => {
    setShowExport(true)
    if (companyId.length > 0) {
      searchParamsTmp.companyId.join(',')
    } else {
      searchParamsTmp.companyId = companyId
    }
    Object.assign(allFilter, searchParamsTmp)
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <div className="search-askforleave-wrap">
        <h4 className="title">其它选项：</h4>
        {
          !showAll ? <span className="askforleave-filter-control" onClick={() => setShowAll(true)}>展开<Icon type="down" /></span>
            : <span className="askforleave-filter-control" onClick={() => setShowAll(false)}>收起<Icon type="up" /></span>
        }
        <Form onSubmit={searchSubmit} {...formItemLayout}>
          <div className={`form-box${showAll ? ' askforleave-form-auto' : ''}`}>
            <FormItem label="订单编号">
              {getFieldDecorator('number')(<Input
                placeholder="请输入订单编号"
              />)}
            </FormItem>
            <FormItem label="厂商ID">
              {getFieldDecorator('vendor')(<Input
                placeholder="请输入厂商ID"
              />)}
            </FormItem>
            <FormItem label="提单人">
              {getFieldDecorator('userName')(<Input
                placeholder="请输入提单人"
              />)}
            </FormItem>
            <FormItem label="负责人">
              {getFieldDecorator('currentName')(<Input 
                placeholder="请输入负责人" 
              />)}
            </FormItem>
            <FormItem label="部门">
              {getFieldDecorator('userDeptId')(<Select
                placeholder="全部"
                showSearch={true}
                optionFilterProp="children"
              >
                {
                  departmentList.map(v => {
                    return !companyId.length ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="客户名称">
              {getFieldDecorator('client')(<Input
                placeholder="请输入客户名称"
              />)}
            </FormItem>
            <FormItem label="OAID">
              {getFieldDecorator('clientId')(<Input
                placeholder="请输入OAID"
              />)}
            </FormItem>
            <FormItem label="代理商名称">
              {getFieldDecorator('agent')(<Input
                placeholder="请输入代理商名称"
              />)}
            </FormItem>
            <FormItem label="XQID">
              {getFieldDecorator('agentId')(<Input
                placeholder="请输入XQID"
              />)}
            </FormItem>
            <FormItem label="老ID">
              {getFieldDecorator('oldAgentId')(<Input
                placeholder="请输入老ID"
              />)}
            </FormItem>
            <FormItem label="类型">
              {getFieldDecorator('type')(<Select
                placeholder="全部"
              >
                {
                  [{ id: 1, name: '代理商' }, { id: 3, name: '直销渠道' }].map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="订单类型">
              {getFieldDecorator('statusType')(<Select
                placeholder="全部"
              >
                {
                  [{id: 0, name: '提单'}, {id: 1, name: '退单'}].map(item => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="项目">
              {getFieldDecorator('project')(<Select
                placeholder="全部"
                onChange={onChangeProject}
              >
                {
                  filter.project && filter.project.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="产品" style={{ display: projectId ? 'block' : 'none' }}>
              {getFieldDecorator('product')(<Select
                placeholder="全部"
                onChange={onChangeProduct}
              >
                {
                  productList.length > 0 && productList.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="业务类型" style={{ display: productId ? 'block' : 'none' }}>
              {getFieldDecorator('business')(<Select
                placeholder="全部"
                onChange={onChangeBusiness}
              >
                {
                  businessList.length > 0 && businessList.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="业绩类型">
              {getFieldDecorator('figtype')(<Select
                placeholder="全部"
              >
                {
                  filter.figure && filter.figure.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="订单状态">
              {getFieldDecorator('auditStatus')(<Select
                placeholder="全部"
                mode="multiple"
                showArrow
              >
                {
                  filter.auditStatus && filter.auditStatus.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="收款类型">
              {getFieldDecorator('coltype')(<Select
                placeholder="全部"
              >
                {
                  filter.coltype && filter.coltype.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="收款状态">
              {getFieldDecorator('colstatus')(<Select
                placeholder="全部"
              >
                {
                  [{ name: '已收款', id: 1 }, { name: '未收款', id: 2 }].map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="合同编号">
              {getFieldDecorator('contractno')(<Input
                placeholder="请输入合同编号"
              />)}
            </FormItem>
            {/* <FormItem label="已分配订单">
               {getFieldDecorator('distribution')(<Select
                 placeholder="全部"
               >
                 {
                   filter.distribution && filter.distribution.map((item, index) => {
                     return <Option key={item.id}>{item.staffName}</Option>
                   })
                 }
               </Select>)}
             </FormItem> */}
            <FormItem label="申请日期">
              {getFieldDecorator('appliedate', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
            <FormItem label="财务通过日期">
              {getFieldDecorator('acceptedcwat', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
            <FormItem label="生效日期">
              {getFieldDecorator('affectedate', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
            <FormItem label="负利到期日期">
              {getFieldDecorator('creditEnd', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
          </div>
          <FormItem className="btns" label=" " colon={false} labelCol={{xs: { span: 4 }, sm: { span: 4 }}} wrapperCol={{xs: { span: 20 }, sm: { span: 20 }}}>
            <div className="staff-filter-btns">
              {showAll && <>
                <h4 className="filter-title">已保存筛选方案：</h4>
                <div className="filter-select">
                  <Select style={{ display: 'inline-block', width: '160px' }} placeholder="请选择" onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)} onSelect={onFilterChecked} value={filterName}>
                    {filterContent.map((item, index) =>
                      <Option value={index} key={index}>
                        <div className="closeStyBtn">
                          {item.name}
                          {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                            e.stopPropagation()
                            onSearchDelete(item.id)
                          }} /></span> : null}
                        </div>
                      </Option>
                    )}
                  </Select>
                </div>
                <Button className="filter-savebtn" onClick={onSaveFilterTerm}>保存筛选条件</Button>
              </>}
              <Auth auths={authList} code="export">
                <Button type="primary" icon="upload" onClick={onExportFile} style={{ marginLeft: 15 }}>导出</Button>
              </Auth>
              <Button onClick={onReset}>重置</Button>
              <Button type="primary" htmlType="submit">筛选</Button>
              <Modal
                title="保存筛选条件"
                visible={filterModelvisible}
                onOk={filterHandleOk}
                onCancel={filterHandleCancel}
              >
                <Input placeholder="请输入筛选名称" onChange={onFilterInfoChange} />
              </Modal>
            </div>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <div className="add-wrap">
        {/* <Auth auths={authList} code="refund-order">
          <Button type="primary" title="退单" icon="" onClick={onRenfundOpen}>退单</Button>
        </Auth> */}
      </div>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="getFortuneOrderChannelAllChangeExportTpl"
        fieldsUrl="getFortuneOrderChannelAllChangeTplItems"
        saveUrl="saveFortuneOrderChannelAllChangeExportTpls"
        exportUrl="exportFortuneOrderChannelAllExport"
        method="export"
        parame={{ ExportType: 'orderchannelall.export', id: selectedIds, ...allFilter }}
        type={1}
        cRef={productsRef}
      />
      <Alert className="corp-count" message={
        <>
          <span>已选择 <span style={{ color: '#168FFF' }}>{checked}</span> 项</span>
          {checked !== 0 && <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onReverse}>反选</span>}
          {checked == 0 //eslint-disable-line
            && <span style={{ marginLeft: 10, color: '#ccc', cursor: 'default' }}>反选</span>}
          <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onAllelection}>全选</span>
        </>
      } type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        className="askforleave-table"
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
        scroll={{ x: 2400 }}
      >
        <Column title="序号" dataIndex="headIndex" fixed="left" width={80} />
        {/* <Column title="订单ID" dataIndex="id" /> */}
        <Column title="订单编号" dataIndex="number" fixed="left" width={160} render={(text, record) => {
          if (record.statusType == 0) {
            return <Link to={`/orderAgent/agentDetail?id=${record.id}&type=allqu&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text}</Link>
          } else {
            return <span className="hrefspan" onClick={() => {
              history.push(`/orderAgent/refundDetail`, {
                ids: record.batchIds,
                auditType: 1,
                vendor: record.vendor,
                type: 'allqu'
              })
            }}>{text}</span>
          }
        }} />
        <Column title="客户名称" dataIndex="client" width={160} />
        <Column title="OAID" dataIndex="clientId" />
        <Column title="代理商名称" dataIndex="agent" width={160} />
        <Column title="XQID" dataIndex="agentId" />
        <Column title="老ID" dataIndex="oldAgentId" />
        <Column title="类型" dataIndex="type"/>
        <Column title="订单类型" key="orderType" render={(text, record) => record.statusType == 0 ? <span style={{color: '#87d068'}}>提单</span> : <span style={{color: '#f50'}}>退单</span>} />
        <Column title="订单状态" dataIndex="auditStatusName" />
        <Column title="分配至" dataIndex="distributionName" />
        
        <Column title="厂商ID" dataIndex="vendor" />
        <Column title="项目" dataIndex="projectName" />
        <Column title="产品" dataIndex="productName" />
        <Column title="业务类型" dataIndex="businessName" />
        <Column title="市场价" dataIndex="colmarketprice" />
        <Column title="实收" dataIndex="colactualprice" />
        <Column title="预计收款" dataIndex="colpredictprice" />
        <Column title="个人利润" dataIndex="colprofit" />
        <Column title="提单人" dataIndex="userName" />
        <Column title="收款类型" dataIndex="coltype" />
        <Column title="收款状态" dataIndex="colstatus" />
        <Column title="退单信息" dataIndex="refundInfo" />
        <Column title="订单负责人" dataIndex="currentUserName" />
        <Column title="负利到期日期" dataIndex="creditEnd" />
        <Column title="主体" dataIndex="supplierCompanyName" />
        {/* <Column title="操作" key="oprate" fixed="right" width={100} render={(text, record) => {
           return <>
             {record.payback === true && <Button type="default" size="small" style={{marginRight: '10px'}} onClick={() => history.push(`/orderAgent/paybackApply?ids=${record.id}&vendor=${record.vendor}`)}>还款</Button>}
           </>
         }} /> */}
      </Table>
      <Drawer
        title="退单申请"
        width={900}
        onClose={closeDrawer}
        visible={visible}
        destroyOnClose={true}
      >
        <Spin spinning={drawerLoading}>
          <RefundList
            setVisible={setVisible}
            setDrawerLoading={setDrawerLoading}
            type={1}
            companyId={companyId}
            history={history}
          />
        </Spin>
      </Drawer>
    </>
  )
}

export default Form.create()(AllAgentList)