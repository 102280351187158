/**
 * 模块名称: 客户名称变更审批详情页
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Descriptions,
  Steps,
  Button,
  Modal,
  Input,
  Table,
  Icon,
  message
} from 'antd'
// import '../assets/style.scss'
import api from '@/api'
import { parseSearch } from '@/utils'
import CryptoJS from 'crypto-js'

const dItem = Descriptions.item
const { Step } = Steps;
const { Column } = Table
let pageSize = 10
let currentPage = 1

const NameChangeDetail = (props) => {
  const { match, location, history } = props
  const [detailData, setDetailData] = useState({})
  const [detailDataId, setdetailDataId] = useState('')
  const [statusList, setStatusList] = useState([])
  const [logConList, setLogConList] = useState([])
  const [buttonAuthList, setButtonAuthList] = useState([])
  const [passModalVisible, setModalVisible] = useState(false) //确认弹框
  const [turnModalVisible, setTurnModalVisible] = useState(false) //驳回弹框
  const [textAreaVal, setTextAreaVal] = useState('') //判断框是否为空
  const [textDisplay, setTextDisplay] = useState(true) //是否提示必选
  const [pasDesc, setPasDesc] = useState('')  //通过意见
  const [showFile, setShowFile] = useState([]) //附件
  const [agenData, setAgeDate] = useState([]) // 所属代理商
  const search = parseSearch(location.search)

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      api.getClientChangeDetail({ id: search.id, type: 0 }, true).then(data => {
        if (search.msgId) {
          api.setMessageReaded({ ids: [search.msgId] })
        }
        setDetailData(data)
        setdetailDataId(data.id)
        setStatusList(data.audit_flow) // 审批流
        setLogConList(data.audit_log) // 审批记录表
        setButtonAuthList(data.auditAuth) // 审批权限
        setShowFile(data.affix) //附件
        setAgeDate(data.agentData) // 所属代理商
      })
      currentPage = search.page ? +search.page : 1
      pageSize = search.limit ? +search.limit : 10
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  // 通过
  const onBtnPassShow = () => {
    setModalVisible(true)
  }

  const onPassCancel = () => {
    setModalVisible(false)
  }

  const onPassDescChange = (e) => {
    setPasDesc(e.target.value)
  }

  const onApprove = () => {
    api.getClientChangeAudit({
      id: detailDataId,
      type: 0,
      remark: pasDesc
    }).then(data => {
      api.getClientChangeDetail({ id: search.id, type: 0 }, true).then(data => {
        setDetailData(data)
        setdetailDataId(data.id)
        setStatusList(data.audit_flow) // 审批流
        setLogConList(data.audit_log) // 审批记录表
        setButtonAuthList(data.auditAuth) // 审批权限
        setShowFile(data.affix) //附件
        setAgeDate(data.agentData) // 所属代理商
      }) //确认通过后
    })
    setModalVisible(false)
  }

  // 驳回
  const onBtnTurnShow = () => {
    setTurnModalVisible(true)
    setTextDisplay(true)
  }

  const onTurnCancel = () => {
    setTurnModalVisible(false)
  }

  const onTurnApprove = () => {
    if (buttonAuthList.reject_msg_require) {
      if (textAreaVal.trim() === '') {
        setTextDisplay(false)
        return
      }
    }
    api.getClientChangeAudit({
      id: detailDataId,
      type: 1,
      remark: textAreaVal
    }).then(data => {
      api.getClientChangeDetail({ id: search.id, type: 0 }, true).then(data => {
        setDetailData(data)
        setdetailDataId(data.id)
        setStatusList(data.audit_flow) // 审批流
        setLogConList(data.audit_log) // 审批记录表
        setButtonAuthList(data.auditAuth) // 审批权限
        setShowFile(data.affix) //附件
        setAgeDate(data.agentData) // 所属代理商
      }) //确认驳回后
    })
    setTurnModalVisible(false)
  }

  const onDescChange = (e) => {
    setTextAreaVal(e.target.value)
    setTextDisplay(true)
  }

  // 驳回样式
  const turnNode = () => {
    return <>
      确认驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#DC143C" />
    </>
  }

  // 同意样式
  const agreeNode = () => {
    return <>
      确认通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
    </>
  }

  // 转上级
  const onTurnUp = () => {
    api.getClientChangeAudit({
      id: detailDataId,
      type: 2,
      remark: ''
    }).then(data => {
      history.push('/csr/addAudit')
    }).catch()
  }

  // 补充资料
  const onReplenish = () => {
    api.getClientChangeAudit({
      id: detailDataId,
      type: 3,
      remark: ''
    }).then(data => {
      history.push('/csr/addAudit')
    }).catch()
  }

  return (
    <>
      <div className="pro-detail">
        <Descriptions title="审批进度" className="pro-detail-smailtitle"></Descriptions>
        <div className="promotionProcess" >
          <Steps>
            {
              statusList.map((item, index) => {
                let status = 'wait'
                if (item.time && item.time.length > 1) {
                  status = 'process'
                }
                return <Step status={status}
                  key={index}
                  value={item.time}
                  title={item.nodeName}
                  description={
                    <>
                      {item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                      <div title={item.statusName}>{item.statusName}</div>
                      <div title={item.time}>{item.time}</div>
                    </>
                    // `${item.persons.map((item)=>item)} ${item.statusName} ${item.time}`
                  }
                />
              })
            }
          </Steps>
        </div>
        <div className="line-box"></div>
        <Descriptions title="变更前客户信息"
          layout="vertical"
          column={4}
          className="pro-detail-smailtitle pro-apply"
        >
          <dItem label="客户名称">
            {detailData.oldName}
          </dItem>
          <dItem label="OAID">
            {detailData.clientId}
          </dItem>
          <dItem label="一级行业">
            {detailData.oldIndustry1Name}
          </dItem>
          <dItem label="二级行业">
            {detailData.oldIndustry2Name}
          </dItem>
          <dItem label="认证号码类型">
            {detailData.oldVerifyTypeName}
          </dItem>
          <dItem label="认证号码">
            {detailData.oldVerifyNumber}
          </dItem>
          <dItem label="所属代理商">
            {
              agenData && agenData.map((item, i) => {
                return <div key={i}>{`${item.name} (QID: ${item.qId})`}</div>
              })
            }
          </dItem>
        </Descriptions>
        <div className="line-box"></div>
        <Descriptions title="变更后客户信息"
          layout="vertical"
          column={4}
          className="pro-detail-smailtitle pro-apply"
        >
          <dItem label="客户名称">
            {detailData.newName}
          </dItem>
          <dItem label="OAID">
            {detailData.clientId}
          </dItem>
          <dItem label="一级行业">
            {detailData.newIndustry1Name}
          </dItem>
          <dItem label="二级行业">
            {detailData.newIndustry2Name}
          </dItem>
          <dItem label="认证号码类型">
            {detailData.newVerifyTypeName}
          </dItem>
          <dItem label="认证号码">
            {detailData.newVerifyNumber}
          </dItem>
        </Descriptions>
        <div className="line-box"></div>
        <Descriptions title="其他信息"
          layout="vertical"
          className="pro-detail-smailtitle pro-apply"
        >
          <dItem label="附件" span={3}>
            <>
            {
              showFile && showFile.length >= 1 ? showFile.map((item, i) => {
                return <div key={i}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
              }) : '无'
            }
            </>
          </dItem>
          <dItem label="备注">
            <>
              {detailData.remark ? detailData.remark : '无'}
            </>
          </dItem>
        </Descriptions>
        {/* 审批记录 */}
        <div className="line-box"></div>
        <Descriptions
          title="审批记录"
          layout="horizontal"
          className="pro-detail-smailtitle"
        >
        </Descriptions>
        <Table
          dataSource={logConList}
          rowKey="nodeName"
          pagination={false}
        >
          <Column title="审批人" dataIndex="userInfo.staffName" />
          <Column title="审批时间" dataIndex="addTime" />
          <Column title="审批状态" dataIndex="status" />
          <Column title="审批意见" dataIndex="chargeMsg" />
        </Table>
        <div className="btn-setting">
          <Button hidden={!buttonAuthList.replenish} onClick={onReplenish}>补充资料</Button>
          <Button hidden={!buttonAuthList.return_up} onClick={onTurnUp}>转上级</Button>
          <Button hidden={!buttonAuthList.hasChargerPower} onClick={onBtnTurnShow}>驳回</Button>
          <Modal
            // title="确认驳回"
            title={turnNode()}
            visible={turnModalVisible}
            onOk={onTurnApprove}
            onCancel={onTurnCancel}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入驳回审批意见" value={textAreaVal} onChange={onDescChange} maxLength={100} />
            </div>
            <span className="poine-text" hidden={textDisplay}>请填写审批意见</span>
          </Modal>
          <Button hidden={!buttonAuthList.hasChargerPower} type="primary" onClick={onBtnPassShow}>通过</Button>
          <Modal
            // title="确认通过"
            title={agreeNode()}
            visible={passModalVisible}
            onOk={onApprove}
            onCancel={onPassCancel}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入通过审批意见" value={pasDesc} onChange={onPassDescChange} maxLength={100} />
            </div>
          </Modal>
        </div>
      </div>
    </>
  )
}

export default NameChangeDetail