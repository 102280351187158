/**
 * 模块名称: 直销订单
 * @author lids@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import FinishedList from './FinishedList'
import AuditList from './AuditList'
import AllDirectList from './AllDirectList'
import DirectDetail from './module/DirectDetail'
import Payback from './module/Payback'
import Paybacks from './module/Paybacks'
import PaybackDetail from './module/PaybackDetail'
import Refund from './module/Refund'
import RefundDetail from './module/RefundDetail'
import RefundEdit from './module/RefundEdit'
import Apply from './module/Apply'
// import AddApply from './module/AddApply'
import './assets/style.scss'

const Index = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/finishedList'} exact component={FinishedList} />
      <Route path={match.path + '/auditList'} exact component={AuditList} />
      <Route path={match.path + '/allDirectList'} exact component={AllDirectList} />
      <Route path={match.path + '/add'} exact component={Apply} />
      <Route path={match.path + '/edit'} exact component={Apply} />
      <Route path={match.path + '/renew'} exact component={Apply} />
      <Route path={match.path + '/directDetail'} exact component={DirectDetail} />
      <Route path={match.path + '/paybackApply'} exact component={Payback} />
      <Route path={match.path + '/paybackApplys'} exact component={Paybacks} />
      <Route path={match.path + '/paybackDetail'} exact component={PaybackDetail} />
      <Route path={match.path + '/refundApply'} exact component={Refund} />
      <Route path={match.path + '/refundEdit'} exact component={RefundEdit} />
      <Route path={match.path + '/refundDetail'} exact component={RefundDetail} />
      <Route component={FinishedList} />
    </Switch>
  )
}

export default Index