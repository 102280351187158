/**
 * 模块名称: 系统公告列表
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect } from 'react'
import BtnGroup from '@/components/BtnGroup'
import { Table, Alert, Form, Input, DatePicker, Select } from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import moment from 'moment'
import { Link } from 'react-router-dom'
import CryptoJS from 'crypto-js'

const Column = Table.Column
const FormItem = Form.Item
const { RangePicker } = DatePicker
const { Option } = Select

let pageSize = 10
let currentPage = 1
let filter = {
  title: '',
  type: '',
  startTime: '',
  endTime: '',
  sortMethod: ''
}

const Main = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, getFieldsValue, resetFields } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    filter = {
      title: '',
      type: '',
      startTime: '',
      endTime: '',
      sortMethod: '',
    }
    getData()
  }, [])

  const getData = () => {
    setLoading(true)
    api.getSysNoticeList({ limit: pageSize, page: currentPage, ...filter }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const onChangeTable = (pagination, filters, sorter) => {
    const order = sorter.order
    filter.sortMethod = (() => {
      switch(order) {
        case 'ascend':
          return 'asc'
        case 'descend':
          return 'desc'
        default:
          return ''
      }
    })()
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    getData()
  }

  const formSubmit = (e) => {
    e.preventDefault()
    currentPage = 1
    history.replace(match.path)
    const vals = getFieldsValue()
    filter.title = vals.title
    filter.type = vals.type
    getData()
  }

  const onChangePicker = (data) => {
    if (data.length === 0) {
      filter.startTime = ''
      filter.endTime = ''
    } else {
      filter.startTime = moment(data[0]).format('YYYY-MM-DD')
      filter.endTime = moment(data[1]).format('YYYY-MM-DD')
    }
  }

  const onResetFields = () => {
    resetFields()
    filter.startTime = ''
    filter.endTime = ''
    filter.title = ''
    filter.type = ''
    currentPage = 1
    history.replace(match.path)
    getData()
  }

  return (
    <>
      <div>
        <Form layout="inline" onSubmit={formSubmit}>
          <FormItem label="标题">
            {getFieldDecorator('title', { initialValue: '' })(<Input placeholder="请输入" />)}
          </FormItem>
          <FormItem label="状态">
            {getFieldDecorator('type', { initialValue: '' })(
              <Select placeholder="请选择" style={{ width: 150 }}>
                <Option value="">全部</Option>
                <Option value="0">未读</Option>
                <Option value="1">已读</Option>
              </Select>
            )}
          </FormItem>
          <FormItem label="创建时间">
            {getFieldDecorator('range', { initialValue: ''})(<RangePicker onChange={onChangePicker} />)}
          </FormItem>
          <FormItem>
            <BtnGroup right style={{ marginTop: 4 }} cancelName="重置" confirmName="查询" onCancel={onResetFields} />
          </FormItem>
        </Form>
      </div>
      <Alert className="alert-ant" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="middle"
        dataSource={list}
        rowKey="id"
        className="table-ant"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1000 }}
        onChange={onChangeTable}
      >
        <Column title="标题" key="title" render={text => <Link to={`${match.path}/details?id=${text.id}&time=${+new Date()}&sign=${CryptoJS.MD5(text.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text.title}</Link>} />
        <Column title="创建时间" dataIndex="addTime" sorter={true} />
        <Column title="状态" dataIndex="status" />
      </Table>
    </>
  )
}

export default Form.create()(Main)