/**
 * 模块名称: 客户名称变更审批
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
// import urls from '@/api/urls'
import {
  Table,
  Form,
  Button,
  Input,
  Select,
  message,
  Icon,
  Popconfirm,
  Tooltip,
  Row,
  Col,
  DatePicker,
  Alert
} from 'antd'
import { parseSearch } from '@/utils'
// import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpFilter'
import { Link } from 'react-router-dom'
import Export from '@/components/Export'
import CryptoJS from 'crypto-js'


const { Column } = Table
const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1

let filter = {
  corpId: '',
  oaId: '',
  oldName: '',
  newName: '',
  status: '',
  auditStatus: '',
  applyStartDate: '',
  applyEndDate: '',
  successStartDate: '',
  successEndDate: ''
}
let selectedIds = ''

const NameChangeAudit = (props) => {
  const productsRef = useRef()
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [expand, setExpand] = useState(false)
  const [auditStatusAll, setAuditStatusAll] = useState([]) // 审批状态
  const [statusAll, setStatusAll] = useState([]) // 状态
  // const [authList, setAuthList] = useState([])
  const search = parseSearch(location.search)
  const [showExport, setShowExport] = useState(false)
  const [selectedCount, setSelectedCount] = useState(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10

    filter = {
      corpId: '',
      oaId: '',
      oldName: '',
      newName: '',
      status: '',
      auditStatus: '',
      applyStartDate: '',
      applyEndDate: '',
      successStartDate: '',
      successEndDate: ''
    }
    getList()
    getFilterData()
    // api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getList = () => {
    setLoading(true)
    api.getClientChangeGetList({ ...filter, limit: pageSize, page: currentPage })
      .then(data => {
        setLoading(false)
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        currentPage = 1
        history.replace(match.path)
        filter = {
          ...filter,
          ...vals,
          applyStartDate: vals.addTime ? vals.addTime[0].format('YYYY-MM-DD') : '',
          applyEndDate: vals.addTime ? vals.addTime[1].format('YYYY-MM-DD') : '',
          successStartDate: vals.successTime ? vals.successTime[0].format('YYYY-MM-DD') : '',
          successEndDate: vals.successTime ? vals.successTime[1].format('YYYY-MM-DD') : ''
        }
        getList()
      }
    })
  }

  // 重置
  const onReset = () => {
    resetFields()
    filter = {
      corpId: filter.corpId,
      oaId: '',
      oldName: '',
      newName: '',
      status: '',
      auditStatus: '',
      applyStartDate: '',
      applyEndDate: '',
      successStartDate: '',
      successEndDate: ''
    }
    currentPage = 1
    history.replace(match.path)
    getList()
  }

  // 筛选条件
  const getFilterData = () => {
    api.getAuditStatusList({type: '15'}).then(res => setAuditStatusAll(res))
    api.getClientChangeFilterData().then(data => {
      setStatusAll(data.status)
    })
  }

  // 删除
  const onDelete = (id) => {
    api.delClientChangeDel({ id }, true).then(() => {
      message.success('删除成功')
      for(let i = 0; i < selectedIds.length; i++){
        if(selectedIds[i] === id){
          selectedIds.splice(i, 1)
          setSelectedCount(selectedIds.length)
          i--
        }
      }
      getList()
    })
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getList()
  }

  const onChangeCorp = (data) => {
    setSelectedCount(0)
    setSelectedRowKeys([])
    filter.corpId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setSelectedCount(selectedRowKeys.length)
      setSelectedRowKeys(selectedRowKeys)
      selectedIds = selectedRowKeys
    },
    selectedRowKeys: selectedRowKeys
  }

  // 导出
  const onShowExportDialog = () => {
    setShowExport(true)
  }
  
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
}
  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        {/* <h4>其他选项: </h4> */}
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="OAID" labelCol={{ span: 8 }}>
                  {getFieldDecorator('oaId')(<Input placeholder="请输入OAID" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="变更前客户名称" labelCol={{ span: 8 }}>
                  {getFieldDecorator('oldName')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="变更后客户名称" labelCol={{ span: 8 }}>
                  {getFieldDecorator('newName')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="状态" labelCol={{ span: 8 }}>
                  {getFieldDecorator('status')(
                    <Select placeholder="全部">
                      {statusAll.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="审批状态" labelCol={{ span: 8 }}>
                  {getFieldDecorator('auditStatus')(
                    <Select placeholder="全部" showSearch={true} optionFilterProp="children">
                      {auditStatusAll.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="申请时间" labelCol={{ span: 8}}>
                  {getFieldDecorator('addTime')(<RangePicker allowClear={false} span={12} style={{ width: '110%' }} />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="通过时间" labelCol={{ span: 8 }}>
                  {getFieldDecorator('successTime')(<RangePicker allowClear={false} span={12} style={{ width: '140%' }} />)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>

        <div className="recruit-search-btns">
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
        </div>
      </Form>
      <div className="line"></div>
      <div className="add-wrap">
        {/* <Auth auths={authList} code="staff-attendance-apply"> */}
          <Button type="primary" icon="upload" onClick={onShowExportDialog}>导出</Button>
        {/* </Auth> */}
      </div>
      <Alert className="corp-count" message={
        <>
          <span>已选择 <span style={{color: '#168FFF'}}>{selectedCount}</span> 项</span>
          <span style={{ marginLeft: 20 }}>总共{count}条数据</span>
        </>
      } type="info" showIcon />
      <Table
        size="middle"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1600 }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        <Column title="OAID" dataIndex="clientId" fixed="left" width={90} />
        <Column title="变更前客户名称"
          dataIndex="oldName"
          width={240}
          ellipsis={true}
          render={(name, record) => <Link to={`${match.path}/detail?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{name}</Link>}
        />
        <Column title="变更后客户名称"
          dataIndex="newName"
          width={240}
          ellipsis={true}
          render={(name, record) => <>{name}</>}
        />
        <Column title="所属代理商" 
         dataIndex="agentData" 
         width={280}
         ellipsis={true}
         render={(text, record) => (
           <>
             {
              record.agentData.map(item => `${item.name} (QID:${item.qId})`).join(',')
             }
           </>
        )}/>
        {/* render={(name, record) => <div title={name}>{name.length > 16 ? name.substring(0, 16) + '...' : name}</div>} */}
        
        <Column title="审批状态" dataIndex="auditStatusName" />
        <Column title="申请时间" dataIndex="addTime" />
        <Column title="通过时间" dataIndex="auditTime" />
        <Column title="申请人" dataIndex="creatorName" />
        <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
          <>
            {
              record.editFlag ?
                <Tooltip title="编辑" placement="bottom">
                  <Link to={`/csr/customer/customerChange?clientId=${record.clientId}&ID=${record.id}`}>
                  <Icon className="operate-icon" type="edit" style={{ color: '#1890ff' }} />
                  </Link>
                </Tooltip>
                : null
            }
            {' '}
            {
              record.delFlag ?
                <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                  <Tooltip title="删除" placement="bottom">
                    <Icon className="operate-icon" style={{ color: 'red' }} type="delete" />
                  </Tooltip>
                </Popconfirm> : null
            }
          </>
        )} />
      </Table>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="getClientChangeExportTpl"
        fieldsUrl="getClientChangeTplItems"
        saveUrl="saveClientChangeLevelTpl"
        exportUrl="exportClientChange"
        method="getlist"
        parame={{ ExportType: 'clientchange.getlist', id: selectedIds, ...filter }}
        cRef={productsRef}
      />
    </>
  )
}

export default Form.create()(NameChangeAudit)
